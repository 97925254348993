<template>
  <div class="main-content q-pa-md">
    <Mural
        :notificationQuantity="wiki_count"
        :breadcrumbs="breadcrumbs"
        route="home"
        :query="{}"
    >
      <DefaultBtn v-if="btnAddWiki" titleButton="Nova Wiki" route="new-wiki"/>
    </Mural>

    <div class="list-training q-mt-md">
      <div class="row justify-center">
        <div>
          <h6>Procure por uma wiki</h6>
          <div class="row q-gutter-sm">
            <q-input
                v-model="titleWiki"
                outlined
                dense
                bg-color="grey-3"
                color="default-pink"
                placeholder="Digite um texto relacionado com uma wiki!"
                style="width: 350px"/>
            <q-btn color="default-pink" no-caps label="Pesquisar" rounded  @click="searchWikiForTitle"
                   :disable="titleWiki <= 3"/>
            <q-btn color="default-pink" label="Limpar" no-caps  rounded outline @click="resetSearch"
                   v-show="titleWiki !=''" />
          </div>
        </div>
      </div>
      <div class="form-input col-12 row items-end">
        <h6 class="q-mt-lg border-padding">Mais acessados</h6>
        <!-- <div class="row">
          <span class="title-input-form">Filtro </span>
        </div> -->
        <div class="row">
          <!-- <q-select
            outlined
            style="width: 350px"
            bg-color="grey-3"
            color="default-pink"
            class="col-11"
            dense
            label="Informe uma categoria"
            emit-value
            map-options
            v-model="category"
            :option s="listCategories"
          />
          <DefaultBtn
            :isDense="true"
            :isInverse="true"
            :showIcon="false"
            :hasPadding="false"
            class="q-ml-sm"
            titleButton="Filtrar"
            @click="getAllWikis(true)"
          /> -->
          <q-btn
              flat
              rounded
              color="default-pink"
              size="12px"
              v-if="btnAddWiki"
          >
            <q-img width="20px" :src="filterimg" spinner-color="white"/>
            <q-menu>
              <q-list style="min-width: 100px">
                <q-item clickable v-close-popup>
                  <q-item-section @click="handleFilterlistUers(0)">
                    <p>Inativos</p>
                  </q-item-section>
                </q-item>
                <q-item
                    clickable
                    v-close-popup
                    @click="handleFilterlistUers(1)"
                >
                  <q-item-section>
                    <p>Ativos</p>
                  </q-item-section>
                </q-item>
                <q-item
                    clickable
                    v-close-popup
                    @click="handleFilterlistUers(2)"
                >
                  <q-item-section>
                    <p>Todos</p>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>

      <WikiCard
          :key="wiki.wikiId"
          v-for="wiki in wikis"
          :wiki="wiki"
          :image="url_aws_bucket + wiki.banner"
          :can-delete="[1, 2].includes(currentTypeWiki)"
      />
      <div class="flex justify-center centers q-mt-sm">
        <DefaultBtn
            :isDense="true"
            :isInverse="true"
            :showIcon="false"
            :hasPadding="false"
            titleButton="Carregar wikis"
            v-if="titleWiki.length === 0 && currentTypeWiki === 1"
            @click="getAllWikis()"
        />
        <DefaultBtn
            :isDense="true"
            :isInverse="true"
            :showIcon="false"
            :hasPadding="false"
            titleButton="Carregar wikis"
            v-if="titleWiki.length === 0 && currentTypeWiki === 2"
            @click="getAll()"
        />
        <DefaultBtn
            :isDense="true"
            :isInverse="true"
            :showIcon="false"
            :hasPadding="false"
            titleButton="Carregar wikis"
            v-if="titleWiki.length === 0 && currentTypeWiki === 0"
            @click="getAllWikisInactive()"
        />
      </div>
    </div>
    <q-inner-loading
        :showing="loading"
        label="Por favor aguarde..."
        label-class="text-teal"
        label-style="font-size: 1.1em"
    >
      <div class="collumn justify-center q-my-md">
        <div style="height: auto; width: 50px; margin: auto">
          <LoadingIcon/>
        </div>
        <label>Por favor aguarde...</label>
      </div>
    </q-inner-loading>
  </div>
</template>

<script setup>
//----IMAGES
import filterimg from "@/assets/icons/filter.svg";

//----COMPONENTS
import Mural from "@/components/Mural.vue";
import WikiCard from "@/components/wiki/cards/WikiCard.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
// import SearchWiki from "@/components/wiki/inputs/SearchWiki.vue";

//----SERVICES
import WikiDataServices from "@/services/WikiDataServices";

//----UTILS
import {ENV} from "@/utils/env";
import CanUserAccessClass from "@/utils/CanUserAccessClass";

//----QUASAR
import {useQuasar} from "quasar";

//----VUEJS
import {ref, onMounted} from "vue";
import {useRouter} from "vue-router";
import {useStore} from "vuex";


//----CONSTANTES
const store = useStore();
const router = new useRouter();
const listCategories = new ref([]);
const $q = new useQuasar();
const breadcrumbs = ref(["Wikis"]);
const canUserAccessClass = new CanUserAccessClass();
const wikiDataServices = new WikiDataServices();
const url_aws_bucket = ENV.URL_AWS_BUCKET;
const pageSize = ref(9);
const currentTypeWiki = ref(2)

let loading = ref(false);
let titleWiki = ref("");
let wikis = ref([]);
let listWikis = ref([]);
let optionsWiki = ref([])
let wiki_count = ref(0);
let category = ref(null);
let pageNumber = ref(1);
let btnAddWiki = ref(true);

onMounted(() => {
  ValidateActionsPage();
  listAllCategories();
  handleFilterlistUers(1)
});

function defineAction(id) {
  let userData = JSON.parse(localStorage.getItem("vuex")).userdata;
  return userData.id == id;
}

async function getAllWikis(emptyWikis = false) {
  loading.value = true;

  if (emptyWikis) {
    wikis.value = [];
    pageNumber.value = 1;
  }

  let data = [];
  if (category.value == null)
    data = await wikiDataServices.getAll(pageNumber.value, pageSize.value);
  else
    data = await wikiDataServices.getForCategory(
        category.value,
        pageNumber.value,
        pageSize.value
    );

  if (data.data != null) {
    pageNumber.value = pageNumber.value + 1;
    data.data.forEach((wiki) => {
      wikis.value.push(wiki);
    });
    wiki_count.value = wiki_count.value + data.data.length;
  }

  loading.value = false;
}

async function getAllWikisInactive(emptyWikis = false) {
  loading.value = true;

  if (emptyWikis) {
    wikis.value = [];
    pageNumber.value = 1;
  }

  let data = [];

  data = await wikiDataServices.getAllInactive(pageNumber.value, pageSize.value);
  if (data.data != null) {
    pageNumber.value = pageNumber.value + 1;
    data.data.forEach((wiki) => {
      wikis.value.push(wiki);
    });
    wiki_count.value = wiki_count.value + data.data.length;
  }

  loading.value = false;
}

async function getAll(emptyWikis = false) {
  loading.value = true;

  if (emptyWikis) {
    wikis.value = [];
    pageNumber.value = 1;
  }

  let active = await wikiDataServices.getAll(
      pageNumber.value,
      pageSize.value
  );
  let inactive = await wikiDataServices.getAllInactive(pageNumber.value, pageSize.value);

  wikis.value = [];
  wikis.value.push(...active.data);
  wikis.value.push(...inactive.data);
  wiki_count.value = wiki_count.value + wikis.value.length;
  pageNumber.value = pageNumber.value + 1;

  loading.value = false;
}

async function handleFilterlistUers(value) {
  const WIKI_INACTIVE = 0;
  const WIKI_ACTIVE = 1;
  const ALL_WIKIS = 2;
  pageNumber.value = 1
  currentTypeWiki.value = value
  switch (value) {
    case WIKI_INACTIVE:
      await getAllWikisInactive(true);
      break;
    case WIKI_ACTIVE:
      await getAllWikis(true);
      break;
    case ALL_WIKIS:
      getAll(true);
      break;
    default:
      await getAllWikis(true);
      break;
  }
}

async function listAllCategories() {
  wikiDataServices
      .getAllCategories(store.state.userdata.company_id)
      .then((response) => {
        response.data.map((item) => {
          listCategories.value.push({
            label: item.name,
            value: item.id,
          });
        });
      })
      .catch((e) => {
        console.log(e);
      });
}

async function ValidateActionsPage() {
  if (!canUserAccessClass.canUserAccess("new-wiki", false)) {
    btnAddWiki.value = false;
    return;
  }
}

async function searchWikiForTitle() {
  let title = titleWiki.value;
  if (!title) return;

  loading.value = true;
  let response = await wikiDataServices.getForTitle(title).catch((error) => {
    alertErro(error.response.data.toString());
  });
  console.log(response.data)
  loading.value = false;
  wikis.value = response.data;
}

function resetSearch() {
  titleWiki.value = ""
  getAllWikis(true)
}

function alertErro(message) {
  $q.notify({
    message: message,
    color: "red-9",
    position: "top",
  });
  loading.value = false;
}

async function filterFnWikis(val, update) {
  if (val === "") {
    update(() => {
      listWikis.value = [];
    });
    return;
  }
  if (val.length >= 3) {
    let dataWikiSearch = await wikiDataServices
        .getForTitle(val)
        .catch((error) => {
          alertErro(error.response.data.toString());
        });
    update(() => {
      listWikis.value = dataWikiSearch.data;
    });
  }
}

function accessWiki(wikiId) {
  titleWiki.value = "Por favor, aguarde...";
  router.push({name: "wiki-explorer", query: {wikiId: wikiId}});
}

</script>

<style lang="scss" scoped>
.search-options {
  padding-left: 1px;
  padding-top: 1px;
  padding-bottom: 0;
  border-bottom: 1px solid #ccc;
}

.border-padding {
  border-top: 1px solid #ccc;
  padding-top: 10px;
}

.main-content {
  width: 100%;
  height: 100%;

  .list-training {
    overflow: auto;
    height: 75vh;
    padding: 1%;
  }

  hr {
    width: 100%;
    height: 1px;
    background: #e5e5e5;
    border: none;
    margin-bottom: 0.5rem;
  }

  @media (min-width: 800px) {
    flex-direction: row;
  }
}
</style>
