<template>
  <div class="containt-training">
    <div class="training row col-12 q-mt-sm">
      <div class="col-12">
        <div class="card-training row col-12">
          <div class="col-md-4 col-sm-12">
            <q-img
              :src="image"
              v-if="wiki.banner != null"
              class="image-training"
              height="124px"
            >
              <div class="absolute-bottom-left section-button-images">
                <RoundedBtn
                  titleButton="Explorar"
                  route="wiki-explorer"
                  :query="{ wikiId: wiki.wikiId }"
                />
              </div>
            </q-img>

            <q-img
              :src="defaultImg"
              class="image-training"
              v-else
              height="124px"
            >
              <div class="absolute-bottom-left section-button-images">
                <RoundedBtn
                  titleButton="Explorar"
                  route="wiki-explorer"
                  :query="{ wikiId: wiki.wikiId }"
                />
              </div>
            </q-img>
          </div>
          <div class="description-training col-md-8 col-sm-12 q-pt-sm">
            <div class="header-description-training row flex col-12 q-pl-sm">
              <div class="header-icon flex items-center col-9">
                <div class="avatar-notification">
                <q-icon
                  v-if="wiki.category_image_path == null"
                  name="account_circle"
                  size="35px"
                />
                <q-img
                  v-else
                  width="100%"
                  height="100%"
                  :src="url_aws_bucket + wiki.category_image_path"
                  spinner-color="white"
                >
                  <template v-slot:error>
                    <q-icon class="avatar" name="account_circle"  size="35px" />
                  </template>
                </q-img>
              </div>
                <div class="title-notification">
                  <h6>
                    {{ wiki.title }}
                  </h6>
                  <small> Criado por: {{ wiki.name_user }}</small>
                </div>
                <!--<q-item-section class="row items-end filter-mural">
                      <div >
                    <q-chip clickable v-for="tag in wiki.tag" :key="tag.name"
                    >
                    {{tag.name}}
                    </q-chip>
                  </div>
                  </q-item-section>-->
              </div>
              <div class="row btn-header-edition flex justify-end col-3">
                <q-btn
                  class="q-mr-sm"
                  v-if="(btnEdit || wiki.edition_other_tutor) && ValidateActionsPage()"
                  round
                  dense
                  size="md"
                  color="default-pink"
                  :to="{ name: 'new-wiki', query: { wikiId: wiki.wikiId } }"
                >
                  <q-img
                    width="70%"
                    :src="editImg"
                    spinner-color="white"
                  />
                </q-btn>
                <q-btn
                  v-if="btnEdit && canDelete"
                  @click="handleDeleteWiki(wiki.wikiId)"
                  icon="delete"
                  round
                  dense
                  size="md"
                  color="default-pink"
                />
              </div>
            </div>
            <div class="body-description-training">
              <div class="list-info col-12 row">
                <div class="col-12" v-if="wiki.dependency != 0">
                  Dependência: <b>{{ wiki.title_dependency }}</b>
                </div>

                <div class="col-8">
                  <q-item-section caption class="description" lines="2">
                    {{ wiki.description }}
                  </q-item-section>
                </div>

                <div class="btn-header-edition flex justify-end col-4">
                  <q-icon name="attach_file" size="xs" />
                  <b> {{ wiki.anexos.length }} </b>
                  <q-icon
                    class="material-icons-outlined q-ml-sm"
                    name="visibility"
                    size="xs"
                  />
                  <b> {{ wiki.number_views }} </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    />
  </div>
</template>

<script>
//----IMAGES
import defaultImg from '@/assets/images/banner_default.png';
import trainingImg from '@/assets/icons/training-icon.svg';
import editImg from '@/assets/icons/edit.svg';

import RoundedBtn from "@/components/shared/buttons/RoundedBtn.vue";
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import WikiDataServices from "@/services/WikiDataServices";

import { ENV  } from "@/utils/env";
import CanUserAccessClass from "@/utils/CanUserAccessClass";
import {useQuasar} from "quasar";

export default {
  name: "WikiCard",
  components: {
    RoundedBtn,
  },
  props: {
    wiki: Object,
    image: String,
    canDelete: Boolean
  },
  setup(props) {
    let router = new useRouter();
    let loading = ref(false);
    let btnEdit = ref(false);
    let userData = JSON.parse(localStorage.getItem("vuex")).userdata;
    let wikiDataServices = new WikiDataServices();
    const url_aws_bucket = ENV.URL_AWS_BUCKET;
    const canUserAccessClass = new CanUserAccessClass();

    const $q = useQuasar()

    onMounted(() => {
      defineAction();
    });

    function defineAction() {
      btnEdit.value = userData.id == props.wiki.userId;
    }

    function ValidateActionsPage() {
      if (!canUserAccessClass.canUserAccess("new-wiki", false)) {
        return false;
      }

      return true;
    }
    
    
    function handleDeleteWiki(wikiId) {
      $q.notify({
        position: 'top',
        type: "warning",
        message: `Você deseja excluir esta wiki intitulada <strong>${props.wiki.title}</strong>?`,
        html: true,
        actions: [
          { label: 'Não', color: 'yellow', handler: () => {}},
          { label: 'Sim', color: 'white', handler: async () => { await deleteWiki(wikiId)}}
        ]
      }, )
    }

    async function deleteWiki(wikiId) {
      const response = await wikiDataServices
        .deleteWiki(wikiId)

      if (response.data){
        await router.go(0);
      }
    }

    return {
      //----IMAGES
      defaultImg,
      trainingImg,
      editImg,
      url_aws_bucket,
      loading,
      btnEdit,
      ValidateActionsPage,
      handleDeleteWiki
    };
  },
};
</script>

<style lang="scss" scoped>
.containt-training {
  border-radius: 20px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
}
.training {
  padding: 1%;
  background: #fdfdfd;
  border-radius: 24px;

  .card-training {
    flex-direction: column;
    @media (min-width: 800px) {
      flex-direction: row;
    }
  }
}

.image-training {
  border-radius: 10px;
}

.section-button-images {
  border-radius: 20px;
  padding: 3px !important;
  margin: 0 0 1% 1%;
  width: 132px;

  background: none;

  /* .button-image {
    background-color: #ffffff;
  } */
}

.participate {
  background-color: #fa6b1f;

  .button-image {
    color: #fa6b1f;
  }
}
.continue {
  background-color: #fe5268;
  .button-image {
    color: var(--q-primary, #fe4e64);
  }
}
.finish {
  background-color: #7cae44;
  .button-image {
    color: #7cae44;
  }
}

.description-training {
  .header-description-training {
    .btn-header-edition {
      height: 33%;
    }
    .header-icon {
      label {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
      }

      .avatar-notification {
        width: 35px;
        height: 35px;
        margin: 0 2%;
        overflow: hidden;
        border-radius: 35px;
        position: relative;
      }
    }
  }
  .title-notification {
    h6 {
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1rem;
      letter-spacing: 0.0125em;
    }
  }

  .body-description-training {
    padding: 2%;
  }
}

.image-training {
  img {
    border-radius: 24px;
  }
}
</style>
